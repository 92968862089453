@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap");

.container-table {
  display: block;
}

h1 {
  font-size: 1.25rem;
  margin-bottom: 0.5rem;
}

.table-container {
  overflow: auto;
  border-radius: 0.8rem;
  box-shadow: 0 0 0.5rem rgba(39, 38, 38, 0.1);
  display: none;
}

@media (min-width: 768px) {
  .table-container {
    display: block;
  }
}

table {
  width: 100%;
}

.table-header {
  background-color: #f9fafb;
  border-bottom-width: 0.125rem;
  border-color: #edf2f7;
}

.table-header-row {
  background-color: #1397cf;
  border-bottom-width: 0.125rem;
  border-color: #edf2f7;
}

.key-heading {
  width: 3.75rem;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-align: left;
}

.details-heading {
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-align: left;
}

.status-heading {
  width: 150px;
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-align: left;
}

.date-heading {
  /* padding: 1.5rem;  */
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-align: left;
}

.price-heading {
  /* padding: 2rem;  */
  padding: 0.75rem;
  font-size: 0.875rem;
  font-weight: 600;
  letter-spacing: 0.025em;
  text-align: left;
}

tbody {
  border-color: #edf2f7;
}

tr {
  background-color: #ffffff;
}

.tr-even {
  background-color: #f9fafb;
}

td {
  padding: 0.75rem;
  font-size: 0.875rem;
  color: #4a5568;
  white-space: nowrap;
}

a {
  font-weight: 600;
  color: #212121;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.cancelled-status {
  padding: 0.375rem;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #FFF;
  background-color: #14B789;
  border-radius: 0.25rem;
}

.shipped-status {
  padding: 0.375rem;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #FFF;
  background-color: #14B789;
  border-radius: 0.25rem;
}

.delivered-status {
  padding: 0.375rem;
  font-size: 0.625rem;
  font-weight: 500;
  letter-spacing: 0.05em;
  color: #FFF;
  background-color: #14B789;
  border-radius: 0.25rem;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1rem;
  display: none;
}

@media (max-width: 768px) {
  .grid-container {
    display: block;
  }
}

.grid-item {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.grid-top {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 0.875rem;
  color: #374151;
  margin-bottom: 0.5rem;
}

.grid-date {
  color: #9ca3af;
}

.grid-desc {
  font-size: 0.875rem;
  color: #4b5563;
  margin-bottom: 0.5rem;
}

.grid-price {
  font-size: 0.875rem;
  font-weight: 500;
  color: #000000;
}

.grid-a {
  font-weight: 600;
  color: #ff4b4b;
  text-decoration: none;
}

.highlight {
  background-color: #f0f0f0;
}

.controls {
  margin-bottom: 1em;
  display: none;
}

@media (min-width: 768px) {
  .controls {
    display: block;
  }
}

.filter-btn,
.sort-btn,
.search-input {
  margin-right: 1em;
  padding: 0.5em 1em;
  cursor: pointer;
  font-weight: 600;
  color: #212121;
  text-decoration: none;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 0, 0.1);
  border-color: #edf2f7;
  font-family: "Poppins", sans-serif;
}

.select_table {
  width: 300px;
  margin-right: 1em;
  padding: 0.5em 1em;
  cursor: pointer;
  font-weight: 600;
  color: #000000;
  text-decoration: none;
  border-radius: 0.5rem;
  border-color: #0080ff;
  font-family: "Poppins", sans-serif;
}


.grid-desc-mobil {
  width: 60%;
  height: auto;
  display: flex;
  border: 1px solid white;
  gap: 5px;
  background-color: #14B789;
  justify-content: center;
  align-items: center;
  padding: 2px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.grid-desc-mobill {
  display: flex;
  justify-content: center;
  align-items: center;
}

.specialty {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
}

.grid-date {
font-size: 20px;
font-weight: bold;
color: #000000;
}

.grid-date-mobil {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  margin-top: -10px;
  margin-bottom: 10px;
}