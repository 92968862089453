/*

TemplateMo 578 First Portfolio

https://templatemo.com/tm-578-first-portfolio

*/

/*---------------------------------------
  CUSTOM PROPERTIES ( VARIABLES )             
-----------------------------------------*/
:root {
  --white-color: #ffffff;
  --primary-color: #313c85;
  --secondary-color: #1397cf;
  --secondary-color-header: #fff;
  --section-bg-color: #f9f9f9;
  --dark-color: #000000;
  --p-color: #a39c9c;
  --border-color: #e9eaeb;
  --featured-border-color: #727aab;

  --body-font-family: "DM Sans", sans-serif;

  --h1-font-size: 62px;
  --h2-font-size: 48px;
  --h3-font-size: 36px;
  --h4-font-size: 32px;
  --h5-font-size: 24px;
  --h6-font-size: 22px;
  --p-font-size: 18px;
  --menu-font-size: 12px;
  --copyright-font-size: 14px;

  --border-radius-large: 100px;
  --border-radius-medium: 20px;
  --border-radius-small: 10px;

  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
}

body {
  background: var(--white-color);
  font-family: var(--body-font-family);
}

/*---------------------------------------
  TYPOGRAPHY               
-----------------------------------------*/

h2,
h3,
h4,
h5,
h6 {
  color: var(--dark-color);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: var(--font-weight-bold);
  letter-spacing: -1px;
}

h1 {
  font-size: var(--h1-font-size);
  letter-spacing: -3px;
}

h2 {
  font-size: var(--h2-font-size);
  color: var(--secondary-color);
  letter-spacing: -3px;
}

h3 {
  font-size: var(--h3-font-size);
}

h4 {
  font-size: var(--h4-font-size);
}

h5 {
  font-size: var(--h5-font-size);
  line-height: normal;
}

h6 {
  font-size: var(--h6-font-size);
}

p {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

ul li {
  color: var(--p-color);
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-normal);
}

a,
button {
  touch-action: manipulation;
  transition: all 0.3s;
}

a {
  color: var(--p-color);
  text-decoration: none;
}

a:hover {
  color: var(--secondary-color);
}

::selection {
  background: var(--secondary-color);
  color: var(--white-color);
}

::-moz-selection {
  background: var(--secondary-color);
  color: var(--white-color);
}

.section-padding {
  padding-top: 120px;
  padding-bottom: 120px;
}

b,
strong {
  font-weight: var(--font-weight-bold);
}

.section-title-wrap {
  background: var(--secondary-color);
  border-radius: var(--border-radius-small);
  padding: 10px 30px;
}

/*---------------------------------------
  AVATAR IMAGE               
-----------------------------------------*/
.avatar-image {
  border-radius: var(--border-radius-large);
  width: 160px;
  height: 160px;
  object-fit: cover;
}

.avatar-image-large {
  width: 90.4px;
  height: 90.4px;
}

/*---------------------------------------
  CUSTOM ICON               
-----------------------------------------*/
.button-58 {
  align-items: center;
  background-color: var(--primary-color);
  border: 2px solid var(--primary-color);
  box-sizing: border-box;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  fill: #000;
  font-family: Inter, sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 48px;
  justify-content: center;
  letter-spacing: -0.8px;
  line-height: 24px;
  min-width: 140px;
  outline: 0;
  border-radius: 5px;
  padding: 0 17px;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.btn-58 {
  margin-left: 20px;
}

.button-58:focus {
  color: #171e29;
}

.button-58:hover {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

.button-58:active {
  background-color: #3385ff;
  border-color: #3385ff;
  fill: #06f;
}

@media (min-width: 768px) {
  .button-58 {
    min-width: 170px;
  }
}

.navbar-icon {
  background: var(--white-color);
  border-radius: var(--border-radius-large);
  color: var(--dark-color);
  width: 47px;
  height: 47px;
  line-height: 47px;
  text-align: center;
}

.is-sticky .navbar-icon {
  background: var(--secondary-color);
  color: var(--white-color);
}

.form-check-icon {
  color: var(--secondary-color);
}

/*---------------------------------------
  CUSTOM BUTTON               
-----------------------------------------*/
.btn-data {
  margin-left: 20px;
}
.custom-btn,
.navbar .custom-btn {
  font-size: var(--p-font-size);
  font-weight: var(--font-weight-bold);
}

.navbar .custom-btn {
  background: transparent;
  border-width: 2px;
  border-style: solid;
  border-color: var(--white-color);
  color: var(--white-color);
  padding: 8px 22px;
}

.navbar-nav

.navbar .custom-btn:hover {
  background: var(--white-color);
  border-color: transparent;
  color: var(--secondary-color);
}

.custom-btn {
  background: var(--secondary-color);
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-weight: var(--font-weight-bold);
  padding: 12px 24px;
}

.custom-btn:hover {
  background: var(--primary-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  color: var(--white-color);
}

.custom-border-btn {
  background: transparent;
  border: 2px solid var(--border-color);
  color: var(--p-color);
}

.custom-border-btn:hover {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.custom-link {
  background-color: var(--primary-color);
}

.custom-link:hover {
  background-color: var(--secondary-color);
}

/*---------------------------------------
  NAVIGATION              
-----------------------------------------*/
.sticky-wrapper {
  position: relative;
  z-index: 222;
  height: auto !important;
}

.is-sticky,
.is-sticky .navbar .container {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%);
}

.is-sticky .navbar .custom-btn {
  border-color: var(--secondary-color);
  color: var(--secondary-color);
}

.is-sticky .navbar .custom-btn:hover {
  background: var(--secondary-color);
  color: var(--white-color);
}

.navbar {
  background: transparent;
  position: absolute;
  z-index: 9;
  right: 0;
  left: 0;
  transition: all 0.3s;
  padding-top: 15px;
  padding-bottom: 0;
}

.navbar .container {
  width: 100%;
  border-radius: var(--border-radius-small);
  padding: 10px 25px;
  background-color: #1397cf;
}

.navbar-brand {
  font-size: var(--h4-font-size);
  font-weight: var(--font-weight-bold);
}

.navbar-brand,
.navbar-brand:hover {
  color: var(--white-color);
}

.navbar-expand-lg .navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
  margin-right: 20px;
  margin-left: 20px;
}

.navbar-nav .nav-link {
  display: inline-block;
  color: rgb(0, 0, 0);
  font-size: var(--menu-font-size);
  font-weight: 760;
  text-transform: uppercase;
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-nav .nav-link::after {
  content: "";
  background: transparent;
  position: absolute;
  bottom: 6px;
  right: 0;
  left: 0;
  width: 100%;
  height: 2px;
}

.navbar-nav .nav-link.active::after,
.navbar-nav .nav-link:hover::after {
  background: var(--secondary-color-header);
}

.navbar-nav .nav-link.active,
.navbar-nav .nav-link:hover {
  color: var(--secondary-color-header);
}

.navbar-toggler {
  border: 0;
  padding: 0;
  cursor: pointer;
  margin: 0;
  width: 30px;
  height: 35px;
  outline: none;
}

.navbar-toggler:focus {
  outline: none;
  box-shadow: none;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
  background: transparent;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before,
.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transition: top 300ms 50ms ease, -webkit-transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease;
  transition: top 300ms 50ms ease, transform 300ms 350ms ease,
    -webkit-transform 300ms 350ms ease;
  top: 0;
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
  transform: rotate(45deg);
}

.navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
  transform: rotate(-45deg);
}

.navbar-toggler .navbar-toggler-icon {
  background: var(--white-color);
  transition: background 10ms 300ms ease;
  display: block;
  width: 30px;
  height: 2px;
  position: relative;
}

.navbar-toggler .navbar-toggler-icon:before,
.navbar-toggler .navbar-toggler-icon:after {
  transition: top 300ms 350ms ease, -webkit-transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease;
  transition: top 300ms 350ms ease, transform 300ms 50ms ease,
    -webkit-transform 300ms 50ms ease;
  position: absolute;
  right: 0;
  left: 0;
  background: var(--white-color);
  width: 30px;
  height: 2px;
  content: "";
}

.navbar-toggler .navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler .navbar-toggler-icon::after {
  top: 8px;
}

/*---------------------------------------
  HERO              
-----------------------------------------*/

.hero {
  position: relative;
  overflow: hidden;
  padding-top: 330px;
  padding-bottom: 330px;
}

@media screen and (min-width: 991px) {
  .hero {
    height: 60vh;
  }
}

@media screen and (min-width: 1024px) {
  .hero {
    height: 60vh;
  }
}

.hero-title,
.hero h2 {
  background: var(--white-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  border-radius: var(--border-radius-large);
  display: inline-block;
  padding: 8px 24px;
}

.hero-title {
  font-size: 44px;
}

.hero h2 {
  font-size: 38px;
}

.hero-text {
  position: relative;
  z-index: 22;
  top: 70px;
}

.hero-image-wrap {
  background: var(--white-color);
  border-radius: 100%;
  width: 350px;
  height: 350px;
  position: absolute;
  z-index: 22;
  top: -50px;
  right: 0;
  left: 0;
  margin: auto;
  pointer-events: none;
}

.hero-image {
  position: absolute;
  z-index: 22;
  top: 0;
  width: 60%;
  margin-left: 100px;
  height: 400px;
  filter: blur(0.5px);
  cursor: pointer;
  -webkit-filter: blur(0.5px);
  border: 3px solid rgb(129, 185, 228);
  padding: 5px;
}

.hero svg {
  position: absolute;
  z-index: 2;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(133, 124, 124, 0.9);
}

.modal-content {
  margin-top: 150px;
  margin-left: 550px;
  display: block;
  width: 400px;
  height: 500px;
  object-fit: cover;
}

#caption {
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  width: 90%;
}

.close {
  position: absolute;
  top: 10px;
  right: 25px;
  color: #fff;
  font-size: 35px;
  font-weight: bold;
  cursor: pointer;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
}

.container-resume {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  padding-top: 200px;
}

.custom-div {
  background-color: #fff;
  padding: 20px;
  margin: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  flex: 1;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.left-div {
  background-color: #f8f9fa;
}
.containerResume {
  display: flex;
  margin-top: 80px;
}

h2 {
  margin-top: 0;
}

p {
  margin: 10px 0;
}

/*---------------------------------------
  ABOUT              
-----------------------------------------*/
.a-con {
  margin-top: -50px;
}

.profile-thumb {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
}

.profile-title {
  border-bottom: 1px solid var(--border-color);
  padding: 15px 30px;
}

.profile-small-title {
  border-right: 1px solid var(--border-color);
  color: var(--secondary-color);
  font-weight: var(--font-weight-bold);
  min-width: 140px;
  margin-right: 10px;
  padding: 13px 30px;
  display: inline-block;
}

.profile-body p {
  margin-bottom: 0;
}

.profile-body p:nth-of-type(even) {
  background: var(--white-color);
}

.about-image {
  border-radius: var(--border-radius-medium);
}

.about-thumb {
  padding-right: 20px;
  padding-left: 20px;
}

.about-thumb p {
  text-align: justify;
  font-size: 19.3px;
}

.show {
  display: none;
}

.readMore {
  margin-top: 10px;
  margin-bottom: 37px;
  font-family: "Work Sans", sans-serif;
  color: rgb(255, 255, 255);
  background-color: #1397cf;
  font-weight: bold;
  border: none;
  width: 200px;
  height: 50px;
}

/*---------------------------------------
  FEATURED              
-----------------------------------------*/
.featured-numbers {
  font-size: var(--h1-font-size);
  line-height: normal;
  display: block;
}

.featured-text {
  color: var(--secondary-color);
}

.featured-border-bottom {
  border-bottom: 1px solid var(--border-color);
}

.featured-border-start {
  border-left: 1px solid var(--border-color);
}

/*---------------------------------------
  CLIENTS              
-----------------------------------------*/

.clients-item-height {
  height: 120px;
}

.clients-image {
  display: block;
  max-width: 100px;
  margin: auto;
  transition: all ease 0.2s;
}

.clients-image:hover {
  transform: scale(1.3);
}

/*---------------------------------------
  SERVICES              
-----------------------------------------*/
.services,
.featured {
  background: var(--section-bg-color);
  border-top: 1px solid var(--border-color);
  border-bottom: 1px solid var(--border-color);
}

.services-thumb {
  background: var(--white-color);
  border: 2px solid transparent;
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  padding: 40px 40px 240px 40px;
  transition: all 0.5s;
}

.services-thumb-up {
  position: relative;
  bottom: 50px;
  margin-bottom: -50px;
}

.services-thumb:hover {
  border: 2px solid var(--secondary-color);
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);
}

.services-thumb:hover .services-icon-wrap {
  background: var(--secondary-color);
  border-color: var(--secondary-color);
  color: var(--white-color);
}

.services-icon-wrap {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-small);
  position: absolute;
  bottom: 0;
  right: 0;
  width: 50%;
  height: 55%;
  transform: rotate(-35deg) translateY(55px);
  transition: all ease 0.5s;
}

.services-icon {
  font-size: 90px;
  position: relative;
  bottom: 15px;
}

.services-thumb:hover .services-price-wrap {
  background: var(--secondary-color);
}

.services-thumb:hover .services-price-overlay {
  background: var(--primary-color);
}

.services-price-wrap {
  background: var(--primary-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  padding: 6px 20px 6px 15px;
  transition: all ease 0.5s;
}

.services-price-text {
  color: var(--white-color);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-bold);
}

.services-price-overlay {
  background: var(--secondary-color);
  border-bottom-left-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  pointer-events: none;
}

/*---------------------------------------
  PROJECTS              
-----------------------------------------*/

.projects-thumb {
  height: 350px;
  background-color: #bed7dc;
  border: 2px solid var(--white-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  margin-top: 24px;
  padding-top: 40px;
  transition: all ease 0.5s;
}

.projects-thumbe {
  height: 600px;
  background-color: #1397cf;
  border: 2px solid var(--white-color);
  border-radius: var(--border-radius-medium);
  position: relative;
  overflow: hidden;
  margin-bottom: 24px;
  margin-top: 24px;
  padding-top: 40px;
  transition: all ease 0.5s;
}

.projects-thumb:hover {
  border-color: var(--secondary-color);
}

.projects-thumb .popup-image {
  width: 100%;
  height: 100%;
}

.projects-image {
  border-radius: var(--border-radius-medium);
  display: block;
  width: 100%;
  height: 360px;
  transition: all ease 0.5s;
}

.projects-image1 {
  border-radius: var(--border-radius-medium);
  display: block;
  width: 100%;
  height: 360px;
  transition: all ease 0.5s;
}

.about-img {
  border-radius: var(--border-radius-medium);
  background-color: #195141;
  display: block;
  width: 100%;
  height: 368px;
  margin-bottom: 20px;
  transition: all ease 0.5s;
}

.projects-title {
  margin-bottom: 20px;
  font-size: 30px;
  color: rgb(81, 81, 193);
}

.projects-tag {
  font-size: 16px;
  font-weight: bolder;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: var(--primary-color);
}

/*---------------------------------------
  CONTACT              
-----------------------------------------*/
.contact {
  background: var(--section-bg-color);
}

.contact-info {
  background: var(--white-color);
  border-top-right-radius: var(--border-radius-small);
  border-bottom-right-radius: var(--border-radius-small);
  padding: 60px 30px 30px 30px;
  height: 100%;
}

.contact-info-border-start {
  border-right: 1px solid var(--border-color);
  border-radius: var(--border-radius-small) 0 0 var(--border-radius-small);
}

.contact-form {
  margin-left: 10px;
}

/*---------------------------------------
  CUSTOM FORM               
-----------------------------------------*/
.custom-form .form-control {
  background: var(--white-color);
  box-shadow: none;
  border: 2px solid var(--border-color);
  color: var(--p-color);
  margin-bottom: 24px;
  padding-top: 13px;
  padding-bottom: 13px;
  outline: none;
}

.form-floating > label {
  color: var(--p-color);
}

.form-check-inline {
  vertical-align: middle;
  width: 100%;
  position: relative;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 24px;
  padding: 0;
}

.custom-form .form-check-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}

.form-check-label-text {
  color: var(--p-color);
  display: block;
  font-size: copyright-font-size;
  margin-top: 5px;
}

.form-check-input[type="checkbox"] {
  background: var(--white-color);
  border: 2px solid var(--border-color);
  box-shadow: none;
  outline: none;
  width: 100%;
  margin-top: 0;
  margin-left: 0;
  padding: 40px 50px;
}

.form-check-input:checked[type="checkbox"] {
  background-image: none;
}

.form-check-input:hover,
.form-check-input:checked {
  background-color: transparent;
  border-color: var(--secondary-color);
}

.custom-form .form-control:hover,
.custom-form .form-control:focus {
  background: transparent;
  border-color: var(--secondary-color);
}

.custom-form .form-floating textarea {
  height: 150px;
  width: 100%;
}

.custom-form button[type="submit"] {
  background: var(--secondary-color);
  border: none;
  border-radius: var(--border-radius-large);
  color: var(--white-color);
  font-weight: var(--font-weight-bold);
  transition: all 0.3s;
  margin-bottom: 0;
}

.custom-form button[type="submit"]:hover,
.custom-form button[type="submit"]:focus {
  background: var(--primary-color);
  border-color: transparent;
}

/*---------------------------------------
  SITE FOOTER              
-----------------------------------------*/
.site-footer {
  border-top: 1px solid var(--border-color);
  padding-top: 50px;
  padding-bottom: 50px;
  text-align: center;
}

.nameee {
  width: 100%;
}

.site-footer-title {
  font-size: var(--menu-font-size);
  color: var(--dark-color);
  text-transform: uppercase;
}

.copyright-text-wrap p,
.copyright-text {
  font-size: var(--copyright-font-size);
}

.copyright-text {
  border-right: 1px solid var(--border-color);
  padding-right: 25px;
  margin-right: 20px;
}

.copyright-text-wrap a {
  font-weight: var(--font-weight-bold);
}

.footer-menu {
  margin: 0;
  padding: 0;
}

.footer-menu-item {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.footer-menu-link {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-medium);
  font-size: var(--copyright-font-size);
  font-weight: var(--font-weight-medium);
  display: inline-block;
  vertical-align: top;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  padding: 6px 14px;
  min-width: 70px;
}

.footer-menu-link:hover {
  background: var(--secondary-color);
  border-color: transparent;
  color: var(--white-color);
}

/*-------------------------------*/
/*--------Resume-----------------*/
/*-------------------------------*/

.resume {
  display: grid;
  grid-gap: 10px;
  width: 100%;
  height: 800px;
  padding-top: 20px;
}

.resume-pg {
  margin-left: 150px;
  width: 80%;
  height: 750px;
}
.input-error {
  border-color: red;
}

.error-message {
  position: absolute;
  color: red;
  top: 100px;
  text-align: center;
  right: 50px;
}

.resume-img {
  width: 230px;
  height: 230px;
  border: 1px solid black;
  cursor: pointer;
  display: block;
  justify-content: center;
  text-align: center;
  position: relative;
}
.resumee-img {
  margin-top: 20px;
  width: 100%;
  height: 60px;
  border: 1px solid #ced4da;
  cursor: pointer;
  display: block;
  justify-content: center;
  text-align: center;
  position: relative;
}

.resume-img img {
  width: 100%;
  height: 100%;
}

.resume-inp {
  margin-left: 500px;
  margin-top: -250px;
}

.name {
  margin-top: 5px;
}

.surname {
  margin-top: 25px;
}

.fathersname {
  margin-top: 25px;
}

.resumeInputs {
  width: 100%;
}

.resumeInputs input {
  width: 100%;
}

.rootResume {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding: 0 290px;
}

.resumeName {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 50px;
}

.selecters {
  display: block;
  width: 100%;
}

.number-resume {
  margin-top: 25px;
}

.resume-btn {
  margin-top: 20px;
  width: 200px;
  height: 50px;
  font-size: 20px;
  font-family: sans-serif;
  border-radius: 100px;
  border-style: none;
  color: white;
  background-color: #1397cf;
  margin-bottom: 50px;
}

.resume-btn:hover {
  background-color: black;
}

.text-black {
  text-align: center;
  padding-top: 150px;
}

@media screen and (max-width: 600px) {
  h2 {
    letter-spacing: 0.1px;
  }
  .text-black {
    padding-top: 150px;
    letter-spacing: 1px;
  }

  
  .navbar-brand {
    margin-left: 30px;
  }

  .resume-inp {
    width: 400px;
    margin-left: 0;
  }

  .resume-pg {
    margin-left: 0;
  }

  .resume-inp {
    margin-left: 0;
  }

  .resume-inp input {
    width: 200px;
  }

  .resumeName {
    display: block;
  }

  .rootResume {
    padding: 20px;
  }

  .resumeInputs {
    margin-top: 20px;
  }

  .resume-img {
    width: 100%;
  }

  .textSelecter {
    text-align: center;
  }

  .resume-btn {
    width: 100%;
  }
  .containerResume {
    display: flex;
    flex-direction: column;
  }
}

.select {
  width: 100%;
  height: 60px;
  font-size: 15px;
  color: #777777;
}

.textSelecter {
  margin-top: 40px;
  font-size: 15px;
}

/*---------------------------------------
  SOCIAL ICON               
-----------------------------------------*/
.social-icon {
  margin: 0;
  padding: 0;
}

.social-icon-item {
  list-style: none;
  display: inline-block;
  vertical-align: top;
}

.social-icon-link {
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius-large);
  font-size: var(--copyright-font-size);
  color: var(--dark-color);
  display: inline-block;
  vertical-align: top;
  margin: 2px 2px 5px 2px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
}

.social-icon-link:hover {
  background: var(--secondary-color);
  border-color: transparent;
  color: var(--white-color);
}

/* footer section start */

#footer {
  background: #f7f7f7;
  padding-top: 7rem;
  background-color: white;
}

#footer2 {
  background: #f7f7f7;
  padding: 3rem;
  margin-top: 0px;
  /* padding-top: 5rem; */
  padding-top: 7rem;
  padding-bottom: 80px;
  background-color: white;
}

.logo-footer {
  max-width: 300px;
}

.social-links {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.social-links h2 {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.social-links img {
  padding-bottom: 25px;
}

.social-icons {
  /* display: flex;
    gap: 3rem; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
  color: #777777;
}

.social-icons a {
  /* font-size: 18px; */
  /* background: #ffffff; */
  /* box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
    padding: 0.4rem 1rem 0.4rem 1rem;
    border-radius: 3px;
	color: #82074a; */
  /* margin-right: 18px; */
  color: #000000;
}

.social-icons a:hover {
  color: #000;
}

.social-icons a i {
  box-shadow: rgb(0 0 0 / 8%) 0px 4px 12px;
  padding: 0.4rem 1rem 0.4rem 1rem;
  border-radius: 3px;
  color: #1397cf;
  font-size: 16px;
  margin-right: 12px;
}

li {
  list-style: none;
}

.useful-link h2 {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.useful-link img {
  padding-bottom: 15px;
}

.use-links {
  line-height: 32px;
}

.use-links li i {
  font-size: 14px;
  padding-right: 8px;
  color: #000000;
}

.use-links li a {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.use-links li a:hover {
  color: #000;
}

.address h2 {
  padding-bottom: 15px;
  font-size: 20px;
  font-weight: 600;
}

.address img {
  padding-bottom: 15px;
}

.address-links li a {
  color: #000000;
  font-size: 15px;
  font-weight: 500;
  color: #000000;
}

.address-links li i {
  font-size: 16px;
  padding-right: 8px;
  color: rgb(53, 62, 159);
}

.address-links li i:nth-child(1) {
  padding-top: 9px;
}

.address-links .address1 {
  font-weight: 500;
  font-size: 15px;
  display: flex;
}

.address-links {
  line-height: 32px;
  color: #000000;
}

.copy-right-sec {
  padding: 1.8rem;
  background: #14b789;
  color: #fff;
  text-align: center;
}

.copy-right-sec a {
  color: #ff1100;
  font-weight: 500;
}

a {
  text-decoration: none;
}

/* footer section end */

/* Partnership )*/

/*---------------------------------------
  RESPONSIVE STYLES               
-----------------------------------------*/
@media screen and (min-width: 1700px) {
  .hero {
    padding-top: 380px;
    padding-bottom: 380px;
  }

  .hero-image-wrap {
    top: -50px;
    width: 400px;
    height: 400px;
  }

  .hero-image {
    min-width: 250px;
  }

  .hero-title,
  .hero h2 {
    font-size: var(--h2-font-size);
  }
}

@media screen and (max-width: 991px) {
  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 32px;
  }

  h4 {
    font-size: 28px;
  }

  h5 {
    font-size: 20px;
  }

  h6 {
    font-size: 18px;
  }

  .section-padding {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .custom-btn,
  .navbar .custom-btn {
    font-size: var(--copyright-text-font-size);
    padding: 8px 16px;
  }

  .navbar .container {
    background: #1397cf;
  }

  .navbar-brand,
  .navbar-brand:hover {
    color: var(--dark-color);
  }

  .navbar-icon {
    background: var(--secondary-color);
    color: var(--white-color);
    width: 44px;
    height: 44px;
    line-height: 44px;
  }

  ..custom-btn {
    border-color: var(--secondary-color);
    color: var(--secondary-color);
  }

  .navbar-toggler .navbar-toggler-icon,
  .navbar-toggler .navbar-toggler-icon:before,
  .navbar-toggler .navbar-toggler-icon:after {
    background: var(--dark-color);
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    margin-left: 0;
  }

  .navbar-nav .nav-link {
    color: var(--p-color);
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .hero {
    padding-top: 200px;
    padding-bottom: 400px;
  }

  .hero-text {
    top: 0;
    margin-bottom: 120px;
  }

  .about-thumb {
    padding-right: 0;
    padding-left: 0;
  }

  .about-numbers {
    font-size: 42px;
  }

  .services-thumb-up {
    bottom: 0;
    margin-bottom: 32px;
  }

  .services-thumb {
    margin-bottom: 32px;
    padding-bottom: 270px;
  }

  .services-icon-wrap {
    width: 45%;
    height: 60%;
  }

  .services .col-lg-10 .row .col-lg-6:last-child,
  .projects .col-lg-4:last-child {
    margin-bottom: 0;
  }

  .projects-thumb {
    margin-top: 0;
    margin-bottom: 32px;
  }

  .contact-info {
    border-radius: 0 0 var(--border-radius-small) var(--border-radius-small);
    padding: 40px 30px;
  }

  .contact-info-border-start {
    border-right: 0;
    border-bottom: 1px solid var(--border-color);
    border-radius: var(--border-radius-small) var(--border-radius-small) 0 0;
  }
}

@media screen and (max-width: 575px) {
  .navbar .container {
    margin-right: 12px;
    margin-left: 12px;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    font-size: 40px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 26px;
  }

  h4 {
    font-size: 22px;
  }

  h5 {
    font-size: 20px;
  }

  .custom-btn,
  .navbar .custom-btn {
    font-size: 13px;
    padding: 6px 12px;
  }

  .navbar-icon {
    font-size: var(--copyright-font-size);
    width: 35.5px;
    height: 35.5px;
    line-height: 35.5px;
  }

  .hero-image-wrap {
    width: 300px;
    height: 300px;
  }

  .hero-image {
    min-width: 270px;
    margin-left: 50px;
    bottom: 20px;
  }
}
