.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.projects-thumb {
  margin-bottom: 10px;
  text-align: center;
}

.projects-thumb img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

.container {
  text-decoration: none;
}

.container-resume {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin: 0 auto;
  width: 80%;
  max-width: 1200px;
  padding-top: 50px;
}

.custom-select-language {
  display: flex;
  width: 100px;
  height: 30px;
  gap: 6px;
}

.right-div {
  padding: 50px;
  background-color: rgb(0, 128, 0);
  transition: transform 0.2s;
  width: 200px;
  height: 200px;
  margin: 0 auto;
}

.right-div:hover {
  box-shadow: 0 0 10px #028391;
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01);
}

.left-div:hover {
  box-shadow: 0 0 10px #028391;
  transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01);
}

.resume-img-wrapper {
  position: relative;
  width: 300px;
  height: 230px;
  margin: 0 auto; /* Centering the image */
}
.file-input {
  display: block;
  margin: 10px 0;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  cursor: pointer;
}

.resume-img {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #f2f2f2;
  border: 2px dashed #ccc;
  width: 200px;
  height: 100%;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.resume-img input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.resume-img img {
  max-width: 100%;
  max-height: 100%;
}

.input-error {
  border-color: red;
}

.error-message {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}


.select_language {
  width: 60px;
  height: 25px;
  font-weight: bolder;
  background-color: #1397cf;
  cursor: pointer;
  color: #fff;
  border-style: none;
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.res_cont {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.inp {
  margin-left: 20px;
  display: grid;
}

.ss {
  display: grid;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
}


.show_mobile {
  display: block !important;
}